import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import SEO from "../components/seo";
import Lightbox from "../components/lightBox";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import Container from "../components/container";
import Columns from "../components/columns";
import { graphql, Link } from "gatsby";
import {
  FaCertificate,
  FaExpeditedssl,
  FaCopy,
  FaServer,
  FaLock,
  FaKey,
  FaEye,
  FaUserLock,
} from "react-icons/fa";
import * as styles from "./index.module.css";

export const query = graphql`
  {
    iso: file(relativePath: { eq: "iso-logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Bezpieczenstwo = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Bezpieczeństwo informacji w Questy"
      headline="Jak zabezpieczamy dane klientów"
    >
      <SEO
        title="Bezpieczeństwo informacji w Questy"
        description="Bezpieczne oprogramowanie ✅ Certyfikat ISO 27001 • Kopie zapasowe • Protokół SSL • Bezpieczne przetwarzanie danych"
        keywords={["bezpieczne oprogramowanie"]}
      />

      <p>
        Jako producent oprogramowania zwracamy szczególną uwagę na{" "}
        <strong>bezpieczny przepływ informacji</strong>. Zależy nam na tym, aby
        klienci otrzymywali usługę na najwyższym poziomie i byli spokojni o
        swoje dane. W tym celu wdrożyliśmy w naszej firmie szereg procedur
        zapewniających bezpieczeństwo przetwarzania informacji.
      </p>
      <br />
      <h3>Jak chronimy Twoje dane?</h3>
      <BenefitBlockWrapper>
        <BenefitBlock
          icon={<FaCertificate size="60px" />}
          title={<strong>Posiadamy certyfikat ISO/IEC 27001</strong>}
        />
        <BenefitBlock
          icon={<FaExpeditedssl size="60px" />}
          title={<strong>Rekomendujemy protokół SSL</strong>}
        />
        <BenefitBlock
          icon={<FaCopy size="60px" />}
          title={<strong>Wykonujemy kopie zapasowe</strong>}
        />
      </BenefitBlockWrapper>
      <br />

      <Container>
        <Columns>
          <div>
            <br />
            <h3
              style={{
                textAlign: "left",
              }}
            >
              Certyfikat ISO/IEC 27001
            </h3>
            <p>
              Nasza firma uzyskała{" "}
              <strong>międzynarodowy certyfikat ISO/IEC 27001</strong>. Oznacza
              to, że spełniamy wszystkie najważniejsze wymogi w zakresie
              bezpieczeństwa przepływu informacji. Ponadto, zobowiązaliśmy się
              do regularnego monitorowania i&nbsp;rozwijania wszelkich
              zabezpieczeń. Zatem procedury ochrony danych będą cały czas
              aktualizowane. Dodatkowo - co 3 lata przechodzimy audyt, by
              upewnić się, że wszystkie kluczowe obszary są zabezpieczane
              zgodnie z najlepszymi, globalnymi praktykami.
            </p>
          </div>
          <div>
            <br />
            <Lightbox
              style={{
                maxWidth: 400,
                margin: "0 auto",
              }}
              images={[data.iso]}
              alts={["bezpieczna platforma b2b"]}
            />
            <center>
              <p>
                {" "}
                Dowiedz się więcej o ISO/IEC 27001
                <strong>
                  <a href="https://questy.pl/certyfikat-iso-questy"> tutaj</a>
                </strong>
                .
              </p>
            </center>
          </div>
        </Columns>
      </Container>
      <Container>
        <Columns>
          <div>
            <br />
            <h3
              style={{
                textAlign: "left",
              }}
            >
              Bezpieczny hosting
            </h3>
            <p>
              Wiemy jak ważny jest bezpieczny serwer, na którym przechowujesz
              kluczowe dane Twojej firmy. Dlatego{" "}
              <strong>
                nawiązaliśmy współpracę z czołowym europejskim DataCenter
              </strong>
              . Co istotne z perspektywy RODO - infrastruktura tego
              doświadczonego hostingodawcy znajduje się na terenie Unii
              Europejskiej.
            </p>
          </div>
          <div>
            <br />
            <br />
            <BenefitBlockWrapper>
              <BenefitBlock icon={<FaServer size="100px" />}></BenefitBlock>
            </BenefitBlockWrapper>
          </div>
        </Columns>
      </Container>

      <h3>
        <strong>Jakie zabezpieczenia stosujemy w naszym oprogramowaniu?</strong>
      </h3>
      <br />
      <Container>
        <Columns>
          <div>
            <br />
            <h3
              style={{
                textAlign: "left",
              }}
            >
              Certyfikat SSL
            </h3>
            <p>
              Wdrażamy u naszych klientów protokył zabezpieczający połączenia
              sieciowe, aby ich{" "}
              <strong>
                oprogramowanie zapewniało poufność przesyłanych informacji
              </strong>
              . Obecnie jest instalowane na większości stron internetowych
              (charakterystyczna zamknięta kłódka przed nazwą domeny na pasku
              adresu w przeglądarce). Poza szyfrowaniem danych i zwiększeniem
              bezpieczeństwa połączenia, korzystnie wpływa to też na{" "}
              <strong>wiarygodność i&nbsp;prestiż firmy</strong>, która o taki
              certyfikat zadbała.
            </p>
          </div>
          <div>
            <br />
            <br />
            <BenefitBlockWrapper>
              <BenefitBlock icon={<FaLock size="100px" />}></BenefitBlock>
            </BenefitBlockWrapper>
          </div>
        </Columns>
      </Container>
      <Container>
        <Columns>
          <div>
            <br />
            <h3
              style={{
                textAlign: "left",
              }}
            >
              Bezpieczne logowanie
            </h3>
            <p>
              Użytkownicy korzystający z oprogramowania Questy, logują się
              poprzez <strong>silne hasło</strong>, zawierające zróżnicowane
              znaki, trudne do rozszyfrowania. Ponadto system cyklicznie wymusza
              zmianę hasła na nowe, dzięki czemu{" "}
              <strong>
                regularnie wzmacnia bezpieczeństwo danych klientów
              </strong>
              . Administrator może też określić limit błędnie wprowadzonego
              hasła, po którego przekroczeniu - następuje zablokowanie dostępu
              do programu. Chroni to przed potencjalnymi próbami włamania na
              konta pracowników.
            </p>
          </div>
          <div>
            <br />
            <br />
            <BenefitBlockWrapper>
              <BenefitBlock icon={<FaKey size="100px" />}></BenefitBlock>
            </BenefitBlockWrapper>
          </div>
        </Columns>
      </Container>
      <Container>
        <Columns>
          <div>
            <br />
            <h3
              style={{
                textAlign: "left",
              }}
            >
              Regularne backupy
            </h3>
            <p>
              Aby zwiększyć bezpieczeństwo zbieranych w oprogramowaniu Questy
              danych, dywersyfikujemy miejsca ich przechowywania. Poza serwerami
              hostingodawcy, dysponujemy dodatkową infrastrukturą, gdzie
              przechowujemy
              <strong> codziennie wykonywane kopie zapasowe</strong> zapisów
              systemowych naszych klientów.
            </p>
          </div>
          <div>
            <br />
            <br />
            <BenefitBlockWrapper>
              <BenefitBlock icon={<FaCopy size="100px" />}></BenefitBlock>
            </BenefitBlockWrapper>
          </div>
        </Columns>
      </Container>
      <Container>
        <Columns>
          <div>
            <br />
            <h3
              style={{
                textAlign: "left",
              }}
            >
              Monitoring stabilności systemu
            </h3>
            <p>
              Posiadamy mechanizm informujący o ewentualnych
              nieprawidłowościach, wykrytych przy przeprowadzaniu codziennego{" "}
              <strong>testu stabilności oprogramowania</strong>. Jeżeli w
              systemie klienta zlokalizowany zostanie jakiś problem -
              automatycznie wysłany zostanie alert do osób odpowiedzialnych za
              obsługę. Dzięki temu mogą szybko zareagować, bez angażowania czasu
              usługobiorcy.
            </p>
          </div>
          <div>
            <br />
            <br />
            <BenefitBlockWrapper>
              <BenefitBlock icon={<FaEye size="100px" />}></BenefitBlock>
            </BenefitBlockWrapper>
          </div>
        </Columns>
      </Container>
      <Container>
        <Columns>
          <div>
            <br />
            <h3
              style={{
                textAlign: "left",
              }}
            >
              Rozbudowane uprawnienia
            </h3>
            <p>
              Każdy z naszych systemów posiada rozbudowaną sekcję uprawnień,
              którą można skonfigurować wedle własnych potrzeb i preferencji.
              Określasz tam profile użytkowników i przypisujesz im{" "}
              <strong>
                dostęp do podglądu i edycji wybranych elementów oprogramowania
              </strong>
              . Administrator może wskazać konkretne osoby korzystające z
              systemu i przypisać im uprawnienia do wprowadzania jednych danych,
              a do innych ograniczyć ich dostęp. Oprogramowanie Questy posiada{" "}
              <strong>
                jeden z najbardziej rozbudowanych schematów elastycznego
                zarządzania uprawnieniami na rynku
              </strong>
              .
            </p>
          </div>
          <div>
            <br />
            <br />
            <BenefitBlockWrapper>
              <BenefitBlock icon={<FaUserLock size="100px" />}></BenefitBlock>
            </BenefitBlockWrapper>
          </div>
        </Columns>
      </Container>

      <br />
      <br />
      <h2> Chcesz porozmawiać o Twoim projekcie? </h2>

      <Container>
        <div
          style={{
            textAlign: "center",
            margin: "20px",
            padding: "10px",
          }}
        >
          <Link className={styles.startNow} to="/kontakt/">
            Skontaktuj się z nami
          </Link>
        </div>
      </Container>
    </FunctionPageLayout>
  );
};

export default Bezpieczenstwo;
